/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const wochentage = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
const heute = new Date();
const aktuellerWochentagIndex = heute.getDay();

// Rotieren der Wochentagsliste
const rotierteWochentage = [...wochentage.slice(aktuellerWochentagIndex + 1), ...wochentage.slice(0, aktuellerWochentagIndex + 1)];

const projectChartDataMicrosites = {
  
  labels: rotierteWochentage,
  data: [233, 24, 32, 114, 135, 141, 132],
  count: 56,
  progressdesktop: 55,
  progressmobile: 45
  
};

export default projectChartDataMicrosites;
