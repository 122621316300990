import Keycloak from 'keycloak-js';

const keycloakConfig = {
  url: window.env.AUTH_URL,
  realm: 'ruv-emp',
  clientId: 'spa-vmfk',
  pkceMethod: 'S256',
  KeycloakResponseType: 'code',
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;