/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// react-github-btn
//import GitHubButton from "react-github-btn";

// @mui material components
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// @mui icons
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import ProgressLineChart from "examples/Charts/LineCharts/ProgressLineChart";
import ProgressDoughnutChart from "examples/Charts/DoughnutCharts/ProgressDoughnutChart";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard/index";

// Custom styles for the Configurator
import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

// Data
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import progressLineChartData from "layouts/dashboard/data/progressLineChartData";
import progressDoughnutChartDataMicrosites from "layouts/dashboard/data/projectChartDataMicrosites";
import progressDoughnutChartDataProductbriks from "layouts/dashboard/data/progressDoughnutChartDataProductbriks";

// Soft UI Dashboard PRO React context
import {
  useSoftUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setMiniSidenav,
  setFixedNavbar,
  setSidenavColor,
} from "context";

function Configurator() {
  const [controller, dispatch] = useSoftUIController();
  const { openConfigurator, transparentSidenav, miniSidenav, fixedNavbar, sidenavColor,medId,title, project,livepath } =
    controller;
  const [disabled, setDisabled] = useState(false);
  const sidenavColors = ["primary", "dark", "info", "success", "warning", "error"];
  

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleTransparentSidenav = () => setTransparentSidenav(dispatch, true);
  const handleWhiteSidenav = () => setTransparentSidenav(dispatch, false);
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleFixedNavbar = () => setFixedNavbar(dispatch, !fixedNavbar);
  
  const navigate = useNavigate();
  const sess_key = sessionStorage.getItem("session_key");
  const [error, setError] = useState(null);
  const [statistics, setStatistics] = useState({});

  // sidenav type buttons styles
  const sidenavTypeButtonsStyles = ({
    functions: { pxToRem },
    boxShadows: { buttonBoxShadow },
  }) => ({
    height: pxToRem(42),
    boxShadow: buttonBoxShadow.main,

    "&:hover, &:focus": {
      opacity: 1,
    },
  });
  
  useEffect(() => {
      const fetchData = async (medLivepath) => {
          try {
              const response1 = await fetch(window.env.API_URL+"/v1/media/"+ medLivepath +"/stats", {
                  headers: {'X-Apikey': sess_key}
              });
  
              if(response1.status === 403) {
                  navigate("/hooks/authentication");
                  return;
              }
  
              const result = await response1.json();
              setStatistics(result);
  
          } catch (error) {
             setError(error)
          }
      };
      if(livepath && openConfigurator ){
        fetchData(livepath);
      }
     
  }, [livepath,openConfigurator]);
  
  useEffect(()=>{
    if(!openConfigurator)
    setStatistics({});
  },[openConfigurator]);


 const wochentage = ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
  const heute = new Date();
  const aktuellerWochentagIndex = heute.getDay();
  const rotierteWochentage = [...wochentage.slice(aktuellerWochentagIndex + 1), ...wochentage.slice(0, aktuellerWochentagIndex + 1)];
  
  // Verwenden der rotiertenWochentage für Ihre Daten
  const projectChartDataMicrosites = {
      labels: rotierteWochentage,
      data: statistics.daily
  };



  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={3}
        pb={0.8}
        px={3}
      >
        <SoftBox>
          <SoftTypography variant="h5">Nutzungsanalyse</SoftTypography>
          <SoftTypography variant="body2" color="text">
            Ermitteln Sie die Nutzung der Microsite.
          </SoftTypography>
        </SoftBox>

        <Icon
          sx={({ typography: { size, fontWeightBold }, palette: { dark } }) => ({
            fontSize: `${size.md} !important`,
            fontWeight: `${fontWeightBold} !important`,
            stroke: dark.main,
            strokeWidth: "2px",
            cursor: "pointer",
            mt: 2,
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </SoftBox>

      <Divider />

      <SoftBox pt={1.25} pb={3} px={3}>
        
        <SoftBox mb={3}>
        {
          Object.keys(statistics).length>0?
          <ProgressLineChart
            color="primary"
            icon="date_range"
            title={project}
            company={title}
            count={statistics.total}
            progressdesktop={statistics.desktop}
            progressmobile={statistics.mobile}
            chart={projectChartDataMicrosites}
          />
          :
          <DefaultInfoCard
          color="primary"
          icon="info"
          description="no content"
          />
        }
        </SoftBox>
        
      </SoftBox>  
      
    </ConfiguratorRoot>
  );
}

export default Configurator;
