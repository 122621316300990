// // /**
// =========================================================
// * Soft UI Dashboard PRO React - v4.0.0
// =========================================================
// 
// * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
// * Copyright 2022 Creative Tim (https://www.creative-tim.com)
// 
// Coded by www.creative-tim.com
// 
//  =========================================================
// 
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// */

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import { SoftUIControllerProvider } from "context";
import { KeycloakProvider } from "globalState";
import keycloak from 'keycloak';

// Soft UI Context Provider

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
        <KeycloakProvider keycloak={keycloak}>
                 <BrowserRouter>
                  <SoftUIControllerProvider>
                    <App />
                  </SoftUIControllerProvider>
                </BrowserRouter>
        </KeycloakProvider>
    
);
