import {useKeycloakHook} from "globalState";

const UseLogout=()=>{
  
  const keycloak  = useKeycloakHook();
  
  const handleLogout=()=>{
    keycloak.logout();
    sessionStorage.clear();
  }
  
  return handleLogout
	 
  }
  
  export default UseLogout;