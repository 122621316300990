import React, {useContext, useState, useEffect} from 'react';

import { useNavigate } from "react-router-dom";


export const ProjekteContext=React.createContext();

//const UpdateProjekteContext=React.createContext();

export function ProjekteContextProvider({children}){
	const [projekteitems, setProjekteitems] = useState([]);
	const [kundenitems, setKundenitems] = useState([]);
	const [ clientId,setClientId]=useState("");
	const [companyshort,setCompanyshort]=useState("");
	const [path, setPath] = useState('');
	const [loaded, setLoaded] = useState(false);
	
	const [mediaid,setMediaid]=useState('');
	const [clientidPath, setClientidPath]=('');
	const [showComponent, setShowComponent]=useState('');
	const [zukunftvorsorgeState, setZukunftvorsorgeState]=useState(null);
	
	
	
   const navigate = useNavigate();
	const sess_key=sessionStorage.getItem("session_key");
	
	
	useEffect(() => {
		function getProjects(url){
			if(path){
				fetch(url+"/v1/project/"+ path, { 
					headers: {'X-Apikey': sess_key,}
					})
				  .then(res =>{
						 if(res.status === 403)navigate("/hooks/authentication");
						 return res.json();
					  })
				  .then(
					(result) => {
					  setLoaded(true);
					  setClientId(result.project[0].client)
					  // setCompanyshort(result.project[0].company_short);
					  setProjekteitems(result.project);
					},
					(error) => {
					  setLoaded(true);
					  console.log(error)
					}
				  )
			}
			}
		
		  getProjects(window.env.API_URL);
		  
	  }, [path]);
	  
	  
	  React.useEffect(()=>{
		  if(mediaid){
		fetch(window.env.API_URL + "/v1/media/component/state?mediaid=" + mediaid,{
		 headers: {'X-Apikey': sess_key}
		   })
		.then(res => {
			 if(res.status === 403)navigate("/hooks/authentication");
			 return res.json()
		 })
		.then(resp=>{
			let activeStatus=resp.filter(ele=>ele.bausteinart==="bav")[0].active;
			setZukunftvorsorgeState(Boolean(activeStatus));
			setShowComponent("show");	
		})
		.catch(err=>console.log(err));
		
		}
	  },[mediaid]);
	  
	  
	  React.useEffect(() => {
		  if(clientId){
		  fetch(window.env.API_URL+"/v1/client/"+clientId,{
		  headers: {'X-Apikey': sess_key,}
		  })
			.then(res =>{
				 if(res.status === 403)navigate("/hooks/authentication");
				 return res.json();
			  })
			.then(
			  (result) => {
				setKundenitems(result.client);
			  },
			  (error) => {
				console.log(error)
			  }
			)
			
			}
		}, [clientId])
	  
	
	  
	  
		
	return(
		<ProjekteContext.Provider 
		value={{
			projekteitems,
			setProjekteitems,
			clientId,
			setPath, 
			loaded,
			showComponent, 
			setShowComponent,
			zukunftvorsorgeState, 
			setZukunftvorsorgeState,
			setMediaid,
			kundenitems,
			setKundenitems
		}}>
			{children}	
		</ProjekteContext.Provider>
	)
	
};


// // SSO-login context
const KeycloakContext = React.createContext();

export const useKeycloakHook = () => useContext(KeycloakContext);

export const KeycloakProvider = ({ children, keycloak }) => {
  return (
	<KeycloakContext.Provider value={keycloak}>
	  {children}
	</KeycloakContext.Provider>
  );
};

